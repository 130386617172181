import { SessionGuard } from './../guards/session.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'consultor', redirectTo: 'lista-consultor', pathMatch: 'full' },
  { 
    path: 'login', 
    canActivate: [SessionGuard],
    loadChildren: () => import('../pages/login/login.module').then( m => m.LoginPageModule),
  },
  { 
    path: 'lista-consultor', 
    canActivate: [AuthGuard],
    loadChildren: () => import('../pages/lista-consultor/lista-consultor.module').then( m => m.ListaConsultorPageModule)
  },
  {
    path: 'perguntas',
    canActivate: [AuthGuard],
    loadChildren: () => import('../pages/perguntas/perguntas.module').then( m => m.PerguntasPageModule)
  },
  {
    path: 'pesquisa/:id',
    loadChildren: () => import('../pages/pesquisa/pesquisa.module').then(m => m.PesquisaPageModule)
  },
  {
    path: 'listagem',
    loadChildren: () => import('../pages/listagem/listagem.module').then(m => m.ListagemModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
