import { Keyboard } from '@ionic-native/keyboard/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { NgModule } from '@angular/core';
import { BrowserModule, HammerGestureConfig } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';


import { IonicStorageModule } from '@ionic/storage';
import { HttpClientModule } from '@angular/common/http';

import { SpinnerProvider } from '../providers/spinner/spinner';
import { AlertProvider } from '../providers/alert/alert';
import { NetworkProvider } from '../providers/network/network';
import { HttpProvider } from '../providers/http/http';
import { PesquisaProvider } from './../providers/pesquisa/pesquisa';
import { IonicRatingModule } from 'ionic4-rating';
import { LoginButtonDirective } from './shared/login-button.directive';
import { DarkColorDirective } from './shared/dark-color.directive';
import { BrMaskerModule } from 'br-mask';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

@NgModule({
    declarations: [AppComponent, LoginButtonDirective, DarkColorDirective],
    imports: [BrowserModule,
        IonicRatingModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        IonicStorageModule.forRoot(),
        HttpClientModule,
        BrMaskerModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })],
    providers: [
        StatusBar,
        SplashScreen,
        ScreenOrientation,
        Keyboard,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        SpinnerProvider,
        AlertProvider,
        NetworkProvider,
        HttpProvider,
        PesquisaProvider,
        { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
