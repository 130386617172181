import { LoadingController } from '@ionic/angular';
import { Injectable } from '@angular/core';

@Injectable()
export class SpinnerProvider {

  public spinner;

  constructor(public loading: LoadingController) {

  }

  async Show(message: string, tempo: number = 600000) {

    this.spinner = await this.loading.create({
      spinner: "circles",
      message: (message || 'Carregando...'),
      duration: tempo
    });


    this.spinner = await this.spinner.present();
  }

  async Hide() {
    console.log("aq", this.spinner);
    return await this.loading.dismiss().then((res) => {
      console.log('Loading dismissed!', res);
    }).catch((error) => {
      console.log('error', error);
    });
  }

}
