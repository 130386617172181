import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';


@Injectable()
export class AlertProvider {

  constructor(
    private alertCtrl: AlertController) {

  }

 async  alert(title: string, message: string) {
    let alert = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons: ['Ok'],
      backdropDismiss: false
    });

    await alert.present();
  }

  async confirm(title: string, message: string, callback: any) {
    let confirm = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons: [
        { text: "Não", role: 'Cancel', handler: () => { console.log('Confirm:Say:No'); } },
        {
          text: "Sim",
          handler: () => {
            callback();
          }
        }
      ]
    });

    await confirm.present()
  }

  async alertOptions(title: string, message: string, textTrue: string, TextFalse: string, callbackTrue: any, callbackFalse: any) {
    let options = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons: [
        { text: TextFalse,
          handler: () => {
            callbackFalse();
          }
        },
        {
          text: textTrue,
          handler: () => {
            callbackTrue();
          }
        }
      ]
    })

    await options.present();
      
  }
}
