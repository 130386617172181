import { Injectable } from '@angular/core';
import { HttpProvider } from '../http/http';
import { ConfigHelper } from '../../app/helpers/configHelper';
import { Storage } from '@ionic/storage';
import { PesquisaModel, RespostaModel, PesquisaLinkModel } from './../../app/models/PesquisaModel';

@Injectable()
export class PesquisaProvider {

  constructor(private httpSrv: HttpProvider,
    public storage: Storage) {
  }


  async getPesquisa(codPesquisa: string): Promise<Array<PesquisaModel>> {
    try {
      let ResultConn = await this.httpSrv.get('consultar/' + codPesquisa, 1, 'Realizando Download...', null, 300000);
      console.log('ResultConn',ResultConn);
      if (ResultConn.success) {
        console.log('providers',ResultConn.data);
        return <Array<PesquisaModel>>ResultConn.data;
      }
    } catch (error) {
      console.log('problema ao carregar pesquisa', error);
    }
  }

  async getPesquisaLink(codPesquisaLink: string): Promise<PesquisaLinkModel> {
    try {
      let ResultConn = await this.httpSrv.get('pesquisa_link/' + codPesquisaLink, 1, 'Realizando Download...', null, 300000);
      console.log('ResultConn',ResultConn);
      if (ResultConn.success) {
        console.log('providers',ResultConn.data);
        return <PesquisaLinkModel>ResultConn.data.pesquisa;
      }
    } catch (error) {
      console.log('problema ao carregar pesquisa', error);
    }
  }

  async getAllPesquisaLinks(codPesquisaLink: string): Promise<Array<PesquisaLinkModel>> {
    try {
      let ResultConn = await this.httpSrv.get('pesquisa_all_links/' + codPesquisaLink, 1, 'Realizando Download...', null, 300000);
      console.log('ResultConn',ResultConn);
      if (ResultConn.success) {
        console.log('providers',ResultConn.data);
        return <Array<PesquisaLinkModel>>ResultConn.data.pesquisas;
      }
    } catch (error) {
      console.log('problema ao carregar pesquisa', error);
    }
  }

  async savePesquisa(respostas: Array<RespostaModel>){
    try {
      let Response = await this.httpSrv.post('salvar/', respostas, 1, null, 'Salvando respostas...', 300000);
      console.log('response: ',Response);
      if (Response.success) {
        return Response.data.status;
      }
    } catch (error) {
      console.log('problema ao salvar respostas', error);
    }
  }

  async savePesquisaLink(pesquisaLink: PesquisaLinkModel){
    try {
      let Response = await this.httpSrv.post('salvar_link/', pesquisaLink, 1, null, 'Salvando pesquisa...', 300000);
      console.log('response: ',Response);
      if (Response.success) {
        return Response.data.status;
      }
    } catch (error) {
      console.log('problema ao salvar pesquisa', error);
    }
  }

}