declare var require: any;
const ambiente = require('../../environments/env.json');

export class ConfigHelper {

    public static Version: string = '1.1.0';

    public static Url: string = ambiente.api;

    public static Token: string = ambiente.token;
    
    public static storageKeys = {

    }

    public static logoHeader: string = ambiente.logoHeader;
    public static logoBottom: string = ambiente.logoBottom;
    public static colorHeader: string = ambiente.colorHeader;
    public static colorBtn: string = ambiente.colorBtn;
    public static headerClass: string = ambiente.headerClass;
    public static btnClass: string = ambiente.btnClass;

}