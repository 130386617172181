import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appLoginButton]'
})
export class LoginButtonDirective {

  @HostBinding('class.btn-red-bg') isClicked = false;
  @HostListener('click') clickAction() {
    console.log('click')
    this.isClicked = !this.isClicked;
  }

}
