import { ConfigHelper } from './../../app/helpers/configHelper';
import { AlertProvider } from './../alert/alert';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpResultModel } from './../../app/models/HttpResultModel';
import { NetworkProvider } from '../network/network';
import {
  timeout
} from 'rxjs/operators';
import { LoadingController } from '@ionic/angular';


@Injectable()
export class HttpProvider {

  constructor(
    private http: HttpClient,
    //private httpNativo: HTTP
    private alertSrv: AlertProvider,
    private networkSrv: NetworkProvider,
    public loading: LoadingController
  ) {
  }

  createHeader(header?: HttpHeaders): HttpHeaders {
    if (!header) {
      header = new HttpHeaders();
    }
    header = header.set('TOKEN', ConfigHelper.Token);

    return header;
  }



  async get(url: string, loading: number = 1, mensagem: string = "Carregando os dados...", tempoSpinner: number = null, timeOut: number = 15000): Promise<HttpResultModel> {

    let header = this.createHeader();
    let _url = ConfigHelper.Url + url;
    //console.log(_url);
    let spinner;

    return new Promise(async (resolve) => {
      if (this.networkSrv.IsOnline) {

        if (loading == 1) {
          spinner = await this.loading.create({ message: mensagem, duration: tempoSpinner });
          await spinner.present();
          // this.spinnerSrv.Show(mensagem, tempoSpinner);
        }

        this.http.get(_url, { headers: header }).pipe(
          timeout(timeOut)
        )
          .subscribe(async _res => {
            if (loading == 1) {
              // this.spinnerSrv.Hide();
              await spinner.dismiss();
            }
            //let json = JSON.parse(_res.data);
            resolve({ success: true, data: _res, err: undefined });
          }, async err => {

            console.log('deu erro');
            if (err.status != 404) {
              //this.alertSrv.toast('Não foi possível consultar os dados, verifique sua conexão e tente novamente', 'bottom');
            }

            if (loading == 1) {
              // this.spinnerSrv.Hide();
              await spinner.dismiss();
            }

            if (err == "TimeoutError: Timeout has occurred") {
              if (loading == 1) {
                this.alertSrv.alert('Sem conexão com o Servidor', 'Infelizmente os dados não podem ser carregados do servidor, verifique sua internet ou tente novamente mais tarde!');
              }
            }

            //this.alertSrv.toast('Não foi possível consultar os dados, verifique sua conexão e tente novamente', 'bottom');
            resolve({ success: false, data: undefined, err: err });
          });
      }
      else {
        this.alertSrv.alert('Sem Conexão com Internet', 'Você está Offline, e infelizmente os dados não podem ser carregados do servidor!');
        resolve({ success: false, data: undefined, err: undefined });
      }
    });
  }


  getLocal(url: string): Promise<HttpResultModel> {

    let header = this.createHeader();
    let _url = url;
    //console.log(_url);

    return new Promise((resolve) => {

      this.http.get(_url, { headers: header })
        .subscribe(_res => {
          //let json = JSON.parse(_res.data);
          resolve({ success: true, data: _res, err: undefined });
        }, err => {
          if (err.status != 404) {
            //this.alertSrv.toast('Não foi possível consultar os dados, verifique sua conexão e tente novamente', 'bottom');
          }

          //this.alertSrv.toast('Não foi possível consultar os dados, verifique sua conexão e tente novamente', 'bottom');
          resolve({ success: false, data: undefined, err: err });
        });

    });
  }


  async post(url: string, model: any, loading: number = 1, verconexao: number = 1, mensagem: string = "Enviando as informações...", timeOut: number = 45000): Promise<HttpResultModel> {

    let header = this.createHeader();
    let _url = ConfigHelper.Url + url;
    let spinner;

    return new Promise(async (resolve) => {

      if (this.networkSrv.IsOnline) {

        if (loading == 1) {
          spinner = await this.loading.create({ message: mensagem, duration: timeOut });
          await spinner.present();
        }

        this.http.post(_url, model, { headers: header })
          .pipe(
            timeout(timeOut)
          )
          .subscribe(async _res => {
            if (loading == 1) {
              await spinner.dismiss();
            }
            //let json = JSON.parse(_res.data);
            resolve({ success: true, data: _res, err: undefined });
          }, async err => {
            if (loading == 1) {
              await spinner.dismiss();
            }
            //console.log('erro status',err);

            if (err.status == 403) {
              let msg = '';
              err.error.forEach(_err => {
                msg += `<li>${_err.retorno}</li>`;
              });
              this.alertSrv.alert('Informação', msg);
            }
            else if (err.status == 400) {
              let msg = '';
              err.error.forEach(_err => {
                msg += `<li>${_err.retorno}</li>`;
              });

              this.alertSrv.alert('Informação', msg);
            }
            else if (err.status == 200) {
              let msg = err.error.text;

              this.alertSrv.alert('Problema ao Salvar', msg);
            }
            else {

              if (verconexao == 1 && loading == 1) {
                this.alertSrv.alert('Sem conexão com o Servidor', 'Infelizmente os dados não podem ser carregados do servidor, verifique sua internet ou tente novamente mais tarde!');
              }

              //this.alertSrv.toast('Não foi possível realizar o processamento da informação, verifique sua conexão e tente novamente', 'bottom');
            }

            resolve({ success: false, data: undefined, err: err });
          });
      }
      else {
        if (verconexao == 1) {
          this.alertSrv.alert('Sem Conexão com Internet', 'Você está Offline, e infelizmente os dados não podem ser carregados do servidor!');
        }

        resolve({ success: false, data: undefined, err: undefined });

      }
    });
  }

  async put(url: string, model: any, loading: number = 1, verconexao: number = 1, mensagem: string = "Enviando as informações...", timeOut: number = 45000): Promise<HttpResultModel> {

    let header = this.createHeader();
    let _url = ConfigHelper.Url + url;
    let spinner;

    return new Promise(async (resolve) => {
      if (this.networkSrv.IsOnline) {

        if (loading == 1) {
          spinner = await this.loading.create({ message: mensagem, duration: timeOut });
          await spinner.present();
        }

        this.http.put(_url, model, { headers: header })
          .pipe(
            timeout(timeOut)
          )
          .subscribe(async _res => {
            console.log("_res", _res);
            if (loading == 1) {
              await spinner.dismiss();
            }
            //let json = JSON.parse(_res.data);
            resolve({ success: true, data: _res, err: undefined });
          },async err => {
            if (loading == 1) {
              await spinner.dismiss();
            }

            console.log('erro status', err);

            if (err.status == 403) {
              let msg = '';
              err.error.forEach(_err => {
                msg += `<li>${_err.retorno}</li>`;
              });
              this.alertSrv.alert('Informação', msg);
            }
            else if (err.status == 400) {
              let msg = '';
              err.error.forEach(_err => {
                msg += `<li>${_err.retorno}</li>`;
              });

              this.alertSrv.alert('Informação', msg);
            }
            else if (err.status == 200) {
              let msg = err.error.text;

              this.alertSrv.alert('Problema na Atualização', msg);
            }
            else {

              if (verconexao == 1 && loading == 1) {
                this.alertSrv.alert('Sem conexão com o Servidor', 'Infelizmente os dados não podem ser carregados do servidor, verifique sua internet ou tente novamente mais tarde!');
              }

              //this.alertSrv.toast('Não foi possível realizar o processamento da informação, verifique sua conexão e tente novamente', 'bottom');
            }

            resolve({ success: false, data: undefined, err: err });
          });
      }
      else {
        if (verconexao == 1) {
          this.alertSrv.alert('Sem Conexão com Internet', 'Você está Offline, e infelizmente os dados não podem ser carregados do servidor!');
        }

        resolve({ success: false, data: undefined, err: undefined });

      }
    });
  }


  async delete(url: string, loading: number = 1, verconexao: number = 1, mensagem: string = "Enviando as informações...", timeOut: number = 15000): Promise<HttpResultModel> {

    let header = this.createHeader();
    let _url = ConfigHelper.Url + url;
    let spinner;

    return new Promise(async (resolve) => {
      if (this.networkSrv.IsOnline) {

        if (loading == 1) {
          spinner = await this.loading.create({ message: mensagem, duration: timeOut });
          await spinner.present();
        }

        this.http.delete(_url, { headers: header })
          .pipe(
            timeout(timeOut)
          )
          .subscribe(async _res => {
            console.log("_res", _res);
            if (loading == 1) {
              await spinner.dismiss();
            }
            //let json = JSON.parse(_res.data);
            resolve({ success: true, data: _res, err: undefined });
          }, async err => {
            if (loading == 1) {
              await spinner.dismiss();
            }

            console.log('erro status', err);

            if (err.status == 403) {
              let msg = '';
              err.error.forEach(_err => {
                msg += `<li>${_err.retorno}</li>`;
              });
              this.alertSrv.alert('Informação', msg);
            }
            else if (err.status == 400) {
              let msg = '';
              err.error.forEach(_err => {
                msg += `<li>${_err.retorno}</li>`;
              });

              this.alertSrv.alert('Informação', msg);
            }
            else {

              if (verconexao == 1 && loading == 1) {
                this.alertSrv.alert('Sem conexão com o Servidor', 'Infelizmente os dados não podem ser carregados do servidor, verifique sua internet ou tente novamente mais tarde!');
              }

              //this.alertSrv.toast('Não foi possível realizar o processamento da informação, verifique sua conexão e tente novamente', 'bottom');
            }

            resolve({ success: false, data: undefined, err: err });
          });
      }
      else {
        if (verconexao == 1) {
          this.alertSrv.alert('Sem Conexão com Internet', 'Você está Offline, e infelizmente os dados não podem ser carregados do servidor!');
        }

        resolve({ success: false, data: undefined, err: undefined });

      }
    });
  }
}