import { Injectable } from '@angular/core';

declare var navigator: any;

@Injectable()
export class NetworkProvider {

  constructor() {

  }

  get IsOnline(): boolean {
    
    return navigator.onLine; 
    
  }

}
