import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[appDarkColor]'
})
export class DarkColorDirective {

  @HostBinding('class.black-bg') isClicked = true;

}
